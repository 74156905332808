import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HealthyHeartsLayout from '../../components/healthy-hearts/HealthyHeartsLayout.jsx';

import { connect } from 'react-redux';
import { HEALTHY_HEARTS_NAVIGATION } from '../../state/actionTypes.js';

class RoutineCheckUps extends React.Component {
    constructor(props){
        super(props);
        props.healthyHeartsNavigation(6);
    }

    render() {
        return (
            <Layout>
                <HealthyHeartsLayout>
                    <div className="HealthyHeartsMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">Even if your dog seems healthy, they should visit the vet at least once a year for a check up. For senior dogs over 6 years of age, more frequent visits are recommended. While you're there, use the opportunity to ask plenty of questions and get some advice on how to keep your dog healthy.</p>
                                <p className="SectionText">You should also make sure your dog receives appropriate vaccinations along with de-worming, flea and tick prevention. For more information visit your vet or visit <a href="https://nexgardspectra.co.nz" rel="noopener noreferrer" target="_blank">nexgardspectra.co.nz</a></p>
                                <p className="SectionText">Dogs have a lot of needs, which must be fulfilled to keep them happy, healthy, and fit. Work with your local veterinary clinic to discover how best you can fulfil these needs.</p>
                                <p className="SectionText">Dogs bond very strongly with their owners, so if they are healthy you can look forward to many happy years with your adoring and always appreciative companion!</p>
                            </div>
                        </div>
                    </div>
                </HealthyHeartsLayout>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        healthyHeartsNavigation: healthyHeartsPage => dispatch({
            type: HEALTHY_HEARTS_NAVIGATION,
            healthyHeartsPage,
        })
    }
}

export default connect(null, mapDispatchToProps)(RoutineCheckUps);
